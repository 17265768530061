import { useEffect, useRef } from "react";
import * as echarts from "echarts";

export interface EChart {
  options: echarts.EChartsCoreOption;
}
let myChart: echarts.EChartsType;

export const EChart = ({ options }: EChart) => {
  const chartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    myChart = echarts.init(chartRef.current as HTMLElement);
  }, []);
  useEffect(() => {
    if (myChart) {
      myChart.setOption(options);
    }
  }, [options]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: 400,
      }}
    />
  );
};
