import React, { useEffect, useState } from "react";
import styles from "./Console.module.scss";
import Content from "./components/content/Content";
import Filter from "./components/Filter/Filter";
import dayjs, { Dayjs } from "dayjs";
import moment, { utc } from "moment";
import { getCosoleDataService } from "../../../services/Console";
import { useNavigate } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";

function Console() {
  const [filterObj, setFilterObj] = useState<{
    users: "ALL Users" | "App Users";
    fromDate: Dayjs;
    toDate: Dayjs;
  }>({
    users: "ALL Users",
    fromDate: dayjs(
      moment()
        .utc()
        .subtract(4, "hours")
        .endOf("day")
        .subtract(6, "days")
        .format("YYYY-MM-DD")
    ),
    toDate: dayjs(moment().utc().subtract(4, "hours").format("YYYY-MM-DD")),
  });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (!token || token.length === 0) {
    navigate("/");
  }
  const [isAllTime, setIsAllTime] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<{
    parameters: any;
    secondParameters: any;
    postByCategories: any;
    usersByCategories: any;
    postByTypes: any;
    postByDuration: any;
    postBySelectionType: any;
    genderData: any;
    ageData: any;
    userData: any;
    postData: any;
  } | null>(null);
  const getData = () => {
    if (!loading) {
      setLoading(true);
    }
    getCosoleDataService(
      token || "",
      filterObj.fromDate,
      filterObj.toDate,
      filterObj.users,
      isAllTime
    ).then(({ data, status }) => {
      if (status === 200) {
        setData(data.data);
      }
      setLoading(false);
    });
  };
  const [refresh, setRefresh] = useState(0);
  const [firstTime, setFirstTime] = useState(true);
  useEffect(() => {
    if (!firstTime) {
      getData();
    }
  }, [refresh, filterObj.users]);
  useEffect(() => {
    if (isAllTime && !firstTime) {
      getData();
    }
  }, [isAllTime]);
  useEffect(() => {
    getData();
    setFirstTime(false);
  }, []);
  return (
    <div className={styles.main}>
      <div className={styles.content}>
        {loading ? (
          <CircularProgress />
        ) : data === null ? (
          <Alert severity="error">Error</Alert>
        ) : (
          <>
            <Filter
              setRefresh={setRefresh}
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              refresh={refresh}
              setIsAllTime={setIsAllTime}
              isAllTime={isAllTime}
            />
            <Content data={data} />
          </>
        )}
      </div>
    </div>
  );
}

export default Console;
