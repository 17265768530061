import React, { useState } from "react";
import classes from "./profile.module.scss";
import doNotDisturbImgOff from "../../../../assets/images/do-not-disturb-off.png";
import doNotDisturbImgOn from "../../../../assets/images/do-not-disturb-on.png";
import restoreImage from "../../../../assets/images/restore.png";
import profileImg from "../../../../assets/images/profile 2.png";
import { S3link } from "../../../../constants/Apis";
import { BlockUserService } from "../../../../services/Question";

interface ProfileProps {
  image: string;
  username: string;
  userId: string;
  isBlocked: boolean;
  onNextClick: () => void;
  changeUserBlock: (userId: string) => void;
}
const Profile: React.FC<ProfileProps> = ({
  image,
  username,
  userId,
  isBlocked,
  onNextClick,
  changeUserBlock,
}) => {
  const [selected, setSelected] = useState<boolean>(false);
  const blockUserProcess = () => {
    BlockUserService(localStorage.getItem("token") || "", userId).then(
      ({ data, status }) => {
        if (status === 200) {
          changeUserBlock(userId);
          setSelected(false);
        }
      }
    );
  };
  return (
    <div className={classes.main}>
      {/* <img src={{S3link+image} alt="avatar" /> */}
      {image ? (
        <img
          className={classes.avatar}
          src={`${S3link}${image}`}
          alt="avatar"
        />
      ) : (
        <div className={classes.avatar}>{username.charAt(0).toUpperCase()}</div>
      )}
      <img
        onClick={() => setSelected(!selected)}
        src={selected ? doNotDisturbImgOn : doNotDisturbImgOff}
        alt="avatar"
      />

      {isBlocked ? (
        <div className={classes.btns}>
          <img
            onClick={() => blockUserProcess()}
            className={classes.restoreImg}
            src={restoreImage}
          />
          <span className={classes.blocked}>Blocked</span>
          <button onClick={onNextClick} className={classes.nextButton}>
            Next
          </button>
        </div>
      ) : (
        <button
          onClick={() => {
            if (selected) {
              blockUserProcess();
            }
          }}
          disabled={!selected}
          className={selected ? classes.activeButton : classes.button}
        >
          Block User
        </button>
      )}
    </div>
  );
};

export default Profile;
