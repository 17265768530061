import React, { useState } from "react";
import classes from "./verifyCode.module.scss";
import OpingoTextLogo from "../../assets/images/LogoType.png";
import Input from "../../components/Input/Input";
import { VerifyCodeService } from "../../services/Signin";
import { useLocation, useNavigate } from "react-router-dom";

function VerifyCode() {
  const [code, setCode] = useState("");
  const location = useLocation();
  const operationId = location.state?.operationId;
  const navigate = useNavigate();

  const verifyCodeHandler = () => {
    VerifyCodeService({ code, operationId }).then(({ data, status }) => {
      if (status === 200) {
        const nextRoute = localStorage.getItem("nextRoute");
        if (nextRoute && nextRoute.length > 0) {
          localStorage.removeItem("nextRoute");
          navigate(nextRoute, { state: { token: data.token } });
        } else {
          navigate("/home", { state: { token: data.token } });
        }
        localStorage.setItem("token", data.token);
      }
    });
  };
  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <img src={OpingoTextLogo} alt="opingo text" />
      </div>
      <div className={classes.logo}></div>
      <div className={classes.form1}>
        <div>
          <Input
            type="text"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            placeholder="Enter security code"
          />
        </div>

        <div>
          <button className={classes.button} onClick={verifyCodeHandler}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default VerifyCode;
