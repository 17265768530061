import { Dayjs } from "dayjs";
import { Apis } from "../constants/Apis";
import { fetchService } from "./service";

const getReportDataService: (
  token: string,
  fromDate: Dayjs,
  toDate: Dayjs,
  type: "ALL Users" | "App Users",
  isAllTime: boolean
) => Promise<{ data: any; status: number }> = async (
  token,
  fromDate,
  toDate,
  type,
  isAllTime
) => {
  return fetchService({
    url: Apis.GET_Report_DATA,
    method: "POST",
    addContentTypeApplicationJson: true,
    token: token,
    showSuccessMessage: false,
    body: {
      fromDate,
      toDate,
      type,
      isAllTime,
    },
  });
};

export { getReportDataService };
