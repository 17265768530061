import { Dayjs } from "dayjs";
import { Apis } from "../constants/Apis";
import { fetchService } from "./service";

const getAdminsDataService: (
  token: string,
  fromDate: Dayjs,
  toDate: Dayjs,
  isAllTime: boolean,
  userid: string
) => Promise<{ data: any; status: number }> = async (
  token,
  fromDate,
  toDate,
  isAllTime,
  userid
) => {
  return fetchService({
    url: Apis.GET_ADMINS_DATA,
    method: "POST",
    addContentTypeApplicationJson: true,
    token: token,
    showSuccessMessage: false,
    body: {
      fromDate,
      toDate,
      isAllTime,
      userid,
    },
  });
};
const getUsersWithGroupService: (
  token: string
) => Promise<{ data: any; status: number }> = async (token) => {
  return fetchService({
    url: Apis.GET_USERS_DATA,
    method: "POST",
    addContentTypeApplicationJson: true,
    token: token,
    showSuccessMessage: false,
    body: {},
  });
};

export { getAdminsDataService, getUsersWithGroupService };
