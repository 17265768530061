import React from "react";
import Categories from "../categories/categories";
import Post, { PostProps } from "../post/post";
import PostInfo from "../postInfo/postInfo";
import Profile from "../profile/profile";
import UserInfo from "../userInfo/userInfo";
import classes from "./posts.module.scss";

interface PostsProps {
  selectedPost: PostProps;
  onNextClick: () => void;
  changeUserBlock: (userId: string) => void;
  changePostBlock: (questionId: string, action: "approve" | "reject") => void;
  categories: { _id: string; title: string; image: string }[];
}

const Posts: React.FC<PostsProps> = ({
  selectedPost,
  onNextClick,
  changeUserBlock,
  categories,
  changePostBlock,
}) => {
  return (
    <div className={classes.main}>
      <div className={classes.left}>
        {selectedPost._id && (
          <Post {...selectedPost} changePostBlock={changePostBlock} />
        )}
      </div>
      <div className={classes.right}>
        {selectedPost._id && <span>{selectedPost.title}</span>}

        <div className={classes.top}>
          {selectedPost._id ? (
            <PostInfo {...selectedPost} />
          ) : (
            <div style={{ flex: 4 }} />
          )}

          <UserInfo {...selectedPost.user} />
        </div>

        <div className={classes.bottom}>
          {selectedPost._id && (
            <Categories
              categories={categories}
              catId={selectedPost.category._id}
            />
          )}
          <Profile
            image={selectedPost.user.avatar}
            username={selectedPost.user.username}
            userId={selectedPost.user._id}
            isBlocked={selectedPost.user.blockedByAdmin ? true : false}
            onNextClick={onNextClick}
            changeUserBlock={changeUserBlock}
          />
        </div>
      </div>
    </div>
  );
};

export default Posts;
