import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Input from "../../../../components/Input/Input";
import { GetAllQuestions } from "../../../../services/Question";
import { PostType } from "../post/post";
import Posts from "../posts/posts";
import SideMenu from "../sideMenu/sideMenu";
import classes from "./content.module.scss";

function Content() {
  const navigate = useNavigate();
  const location = useLocation();
  const { postId } = useParams();
  const [posts, setPosts] = useState<PostType[]>([]);
  const [categories, setCategories] = useState<
    { title: string; _id: string; image: string }[]
  >([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedPost, setSelectedPost] = useState<PostType | null>(null);
  const [filterType, setFilterType] = useState("");
  const [flagType, setFlagType] = useState("All");
  const [sortType, setSortType] = useState("Newest");

  function goToNext() {
    setActiveIndex((oldVal) => {
      return oldVal + 1;
    });
  }

  function goToPrevious() {
    setActiveIndex((oldVal) => {
      if (oldVal > 0) {
        return oldVal - 1;
      }
      return 0;
    });
  }
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
    GetAllQuestions(
      localStorage.getItem("token") || "",
      filterType,
      1,
      postId || "",
      flagType,
      sortType,
      ""
    ).then(({ data, status }) => {
      if (status === 200) {
        setPosts(data.questions);
        setSelectedPost(data.questions[activeIndex]);
        if (categories.length === 0) {
          setCategories(data.categories);
        }
      } else if (status === 401) {
        localStorage.clear();
        localStorage.setItem(
          "nextRoute",
          `${location.pathname}${location.search}`
        );
        navigate("/");
      }
    });
  }, [filterType, flagType, sortType]);
  useEffect(() => {
    if (posts.length > 0 && activeIndex < posts.length) {
      setSelectedPost(posts[activeIndex]);
    }
    if (posts.length > 0 && activeIndex > posts.length - 3) {
      GetAllQuestions(
        localStorage.getItem("token") || "",
        filterType,
        page + 1,
        postId || "",
        flagType,
        sortType,
        searchText
      ).then(({ data, status }) => {
        if (status === 200) {
          setPage(page + 1);
          setPosts([...posts, ...data.questions]);
          // if ([...posts, ...data.questions][activeIndex]) {
          //   setSelectedPost([...posts, ...data.questions][activeIndex]);
          // }
          if (categories.length === 0) {
            setCategories(data.categories);
          }
        }
      });
    }
  }, [activeIndex]);
  const changeUserBlock = (userId: string) => {
    const temp = posts;
    temp.forEach((t) => {
      if (t.user._id === userId) {
        if (t.user.blockedByAdmin === null) {
          t.user.blockedByAdmin = new Date().toDateString();
          t.rejecteddAt = new Date().toDateString();
        } else {
          t.user.blockedByAdmin = null;
          t.rejecteddAt = null;
        }
      }
    });
    setPosts([...temp]);
  };
  const changePostBlock = (
    questionId: string,
    action: "approve" | "reject"
  ) => {
    const temp = posts;
    temp.forEach((t) => {
      if (t._id === questionId) {
        if (action === "reject") {
          t.rejecteddAt = new Date().toDateString();
        } else if (action === "approve") {
          t.rejecteddAt = null;
        }
      }
    });
    setPosts([...temp]);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "ArrowUp") {
      goToPrevious();
    } else if (event.key === "ArrowDown") {
      goToNext();
    }
  };
  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const [searchText, setSearchText] = useState("");
  const searchProccess = (clear = false) => {
    if (page !== 1) {
      setPage(1);
    }
    if (clear) {
      setSearchText("");
    }
    GetAllQuestions(
      localStorage.getItem("token") || "",
      filterType,
      1,
      postId || "",
      flagType,
      sortType,
      clear ? "" : searchText
    ).then(({ data, status }) => {
      if (status === 200) {
        setPosts(data.questions);
        setSelectedPost(data.questions[activeIndex]);
        if (categories.length === 0) {
          setCategories(data.categories);
        }
      } else if (status === 401) {
        localStorage.clear();
        localStorage.setItem(
          "nextRoute",
          `${location.pathname}${location.search}`
        );
        navigate("/");
      }
    });
  };
  return (
    <>
      <div className={classes.header}>
        {["", "report"].includes(filterType) &&
          ["All", "AWS", "Keywords", "Users", "Not Flagged"].map((item) => (
            <div
              key={item}
              onClick={() => {
                setFlagType(item);
              }}
              style={{
                backgroundColor: item === flagType ? "#b9dafe" : "transparent",
              }}
            >
              {item}
            </div>
          ))}
        <div
          style={{
            marginRight: 10,
            marginLeft: 10,
            height: "100%",
            width: 1,
            backgroundColor: "#ccc",
          }}
        />
        <div>Sorted by:</div>
        {["Newest", "Oldest"].map((item) => (
          <div
            key={item}
            onClick={() => {
              setSortType(item);
            }}
            style={{
              backgroundColor: item === sortType ? "#b9dafe" : "transparent",
            }}
          >
            {item}
          </div>
        ))}
        <div
          style={{
            marginRight: 10,
            marginLeft: 10,
            height: "100%",
            width: 1,
            backgroundColor: "#ccc",
          }}
        />
        <div>Search:</div>
        <input
          type="text"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          placeholder="Search..."
        />
        <Button
          onClick={() => searchProccess()}
          size="small"
          variant="contained"
          color="primary"
        >
          Search
        </Button>
        <Button
          onClick={() => searchProccess(true)}
          size="small"
          color="primary"
        >
          Clear
        </Button>
      </div>
      <div className={classes.main}>
        <SideMenu
          activeIndex={activeIndex}
          goToNext={goToNext}
          goToPrivious={goToPrevious}
          filterType={filterType}
          setFilterType={setFilterType}
        />
        {selectedPost && (
          <Posts
            changeUserBlock={changeUserBlock}
            onNextClick={goToNext}
            selectedPost={selectedPost}
            categories={categories}
            changePostBlock={changePostBlock}
          />
        )}
      </div>
    </>
  );
}

export default Content;
