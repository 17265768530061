import React from "react";
import { S3link } from "../../../../../constants/Apis";
import { PostProps } from "../../post/post";
import classes from "./split.module.scss";

const SplitPost: React.FC<PostProps> = ({ ...post }) => {
  return (
    <div className={classes.main}>
      <div className={classes.options}>
        {post.options.map((option, index) => (
          <div key={index} className={classes.option}>
            <div className={classes.caption}>
              <div className={classes.labelBox}>
                <span className={classes.label}>{option.label} </span>
              </div>

              <span className={classes.percent}>
                {`${
                  post.votesCount === 0
                    ? 0
                    : Math.round((100 * option.votesCount) / post.votesCount)
                }
            %`}
              </span>
            </div>

            <img src={S3link + option.image} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SplitPost;
