import React from "react";
import { PostProps } from "../../post/post";
import classes from "./textPost.module.scss";

const TextPost: React.FC<PostProps> = ({ ...post }) => {
  return (
    <div className={classes.main}>
      <div className={classes.options}>
        {post.options.map((option, index) => (
          <div key={index} className={classes.option}>
            <span className={classes.label}>{option.label} </span>
            <span className={classes.percent}>
              {`${
                post.votesCount === 0
                  ? 0
                  : Math.round((100 * option.votesCount) / post.votesCount)
              }
            %`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextPost;
