import React, { useState } from "react";
import classes from "./login.module.scss";
import OpingoTextLogo from "../../assets/images/LogoType.png";
import OpingoLogo from "../../assets/images/opingo.png";
import Input from "../../components/Input/Input";
import { SigninService } from "../../services/Signin";
import { useNavigate } from "react-router-dom";

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const loginHandler = () => {
    SigninService({ email: userName, password }).then(({ data, status }) => {
      if (status === 200) {
        navigate("/verify-code", { state: { operationId: data.operationId } });
      }
    });
  };

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <img src={OpingoTextLogo} alt="opingo text" />
      </div>
      <div className={classes.logo}>
        <img src={OpingoLogo} alt="opingo logo" />
      </div>
      <div className={classes.form1}>
        <div>
          {" "}
          <Input
            type="text"
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            placeholder="Email"
          />
        </div>

        <div>
          {" "}
          <Input
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="Password"
          />
        </div>
        <div>
          <button className={classes.button} onClick={loginHandler}>
            Sign in
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
