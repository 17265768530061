import React, { useEffect, useState } from "react";
import styles from "./Filter.module.scss";
import SelectDate from "./components/SelectDate/SelectDate";
import { Dayjs } from "dayjs";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

interface FilterProps {
  filterObj: {
    users: "ALL Users" | "App Users";
    fromDate: Dayjs;
    toDate: Dayjs;
  };
  setFilterObj: (value: FilterProps["filterObj"]) => void;
  setRefresh: (val: number) => void;
  refresh: number;
  setIsAllTime: (val: boolean) => void;
  isAllTime: boolean;
}

function Filter({
  filterObj,
  setFilterObj,
  setRefresh,
  refresh,
  isAllTime,
  setIsAllTime,
}: FilterProps) {
  const [fromDate, setFromDate] = useState<Dayjs>(filterObj.fromDate);
  const [toDate, setToDate] = useState<Dayjs>(filterObj.toDate);

  const filters = [
    {
      text: "ALL Users",
      onClick: () => setFilterObj({ ...filterObj, users: "ALL Users" }),
    },
    {
      text: "App Users",
      onClick: () => setFilterObj({ ...filterObj, users: "App Users" }),
    },
  ];

  useEffect(() => {
    setFilterObj({ ...filterObj, fromDate: fromDate });
  }, [fromDate]);

  useEffect(() => {
    setFilterObj({ ...filterObj, toDate: toDate });
  }, [toDate]);

  return (
    <div className={styles.main}>
      <div className={styles.filtersContainer}>
        <span>Filters:</span>

        <div className={styles.filters}>
          {filters.map((filter, index) => (
            <span
              key={index}
              className={`${styles.filterItem} ${
                filterObj.users === filter.text ? styles.activeFilterItem : ""
              }`}
              onClick={filter.onClick}
            >{`${index + 1}.${filter.text}`}</span>
          ))}
        </div>
      </div>
      <FormControl>
        <RadioGroup
          row
          value={isAllTime ? "All Time" : "Date Filter"}
          onChange={(e) => {
            setIsAllTime(e.target.value === "All Time");
          }}
        >
          <FormControlLabel
            value="All Time"
            control={<Radio />}
            label="All Time"
          />
          <FormControlLabel
            value="Date Filter"
            control={<Radio />}
            label="Date Filter"
          />
        </RadioGroup>
      </FormControl>
      <div className={styles.datepickerContainer}>
        {!isAllTime && (
          <>
            <SelectDate
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
            />
            <Button
              variant="contained"
              onClick={() => {
                setRefresh(refresh + 1);
              }}
            >
              Apply
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default Filter;
