import React from "react";
import styles from "./Content.module.scss";
import Statistics from "./components/Statistics/Statistics";
import Tables from "./components/Statistics/Tables/Tables";
import { EChart } from "../../../../../components/EChart";
import moment from "moment";

const dates = [
  moment().utc().subtract(4, "hours").subtract(6, "day").format("MM/DD"),
  moment().utc().subtract(4, "hours").subtract(5, "day").format("MM/DD"),
  moment().utc().subtract(4, "hours").subtract(4, "day").format("MM/DD"),
  moment().utc().subtract(4, "hours").subtract(3, "day").format("MM/DD"),
  moment().utc().subtract(4, "hours").subtract(2, "day").format("MM/DD"),
  moment().utc().subtract(4, "hours").subtract(1, "day").format("MM/DD"),
  moment().utc().subtract(4, "hours").format("MM/DD"),
];

function Content({
  data,
}: {
  data: {
    parameters: any;
    secondParameters: any;
    postByCategories: any;
    usersByCategories: any;
    postByTypes: any;
    postByDuration: any;
    postBySelectionType: any;
    genderData: any;
    ageData: any;
    userData: any;
    postData: any;
  };
}) {
  return (
    <div className={styles.main}>
      <Statistics
        parameters={data.parameters}
        secondParameters={data.secondParameters}
      />
      <div className={styles.charts1}>
        <div>
          <EChart
            options={{
              title: {
                text: "Number of Users",
                left: "center",
              },
              xAxis: {
                type: "category",
                data: dates,
              },
              yAxis: {
                type: "value",
              },
              tooltip: {
                trigger: "item",
              },
              series: [
                {
                  data: data.userData,
                  type: "bar",
                  name: "Number of Users",
                },
              ],
            }}
          />
        </div>
        <div>
          <EChart
            options={{
              title: {
                text: "Number of Posts",
                left: "center",
              },
              xAxis: {
                type: "category",
                data: dates,
              },
              yAxis: {
                type: "value",
              },
              tooltip: {
                trigger: "item",
              },
              series: [
                {
                  data: data.postData,
                  type: "bar",
                  name: "Number of Posts",
                },
              ],
            }}
          />
        </div>
      </div>
      <div className={styles.charts}>
        <div>
          <EChart
            options={{
              title: {
                text: "Gender",
                left: "center",
              },
              tooltip: {
                trigger: "item",
              },
              legend: {
                orient: "vertical",
                left: "left",
              },
              series: [
                {
                  name: "Gender",
                  type: "pie",
                  radius: "50%",
                  data: data.genderData,
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              ],
            }}
          />
        </div>
        <div>
          <EChart
            options={{
              title: {
                text: "Age",
                left: "center",
              },
              xAxis: {
                type: "category",
                data: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
              },
              yAxis: {
                type: "value",
              },
              tooltip: {
                trigger: "item",
              },
              series: [
                {
                  data: data.ageData,
                  type: "bar",
                  name: "Age",
                },
              ],
            }}
          />
        </div>
      </div>
      <Tables
        postByCategories={data.postByCategories}
        usersByCategories={data.usersByCategories}
        postByTypes={data.postByTypes}
        postByDuration={data.postByDuration}
        postBySelectionType={data.postBySelectionType}
      />
    </div>
  );
}

export default Content;
