import React from "react";
import classes from "./header.module.scss";
import MenuImg from "../../../../assets/images/Menu.png";
import logoImg from "../../../../assets/images/LogoWhite_NoBackground 1.png";
import { useLocation, useNavigate } from "react-router-dom";
interface HeaderProps {
  logoutHandler: () => void;
}

function Header({ logoutHandler }: HeaderProps) {
  const menus = [
    { title: "Console", route: "/console/", index: 1 },
    { title: "Content", route: "/home/", index: 2 },
    { title: "Report", route: "/reports/", index: 3 },
    { title: "Admins", route: "/admins/", index: 4 },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className={classes.main}>
      <div className={classes.left}>
        <img src={MenuImg} alt="menu" />
        {menus.map((menu) => (
          <span
            key={menu.index}
            className={
              location.pathname.toLocaleLowerCase() ===
              menu.route.toLocaleLowerCase()
                ? classes.left__active
                : ""
            }
            onClick={() => navigate(menu.route)}
          >
            {menu.title}
          </span>
        ))}
      </div>
      <div className={classes.right}>
        <span>Admin1</span>
        <img
          style={{ cursor: "pointer" }}
          onClick={logoutHandler}
          src={logoImg}
          alt="logo"
        ></img>
      </div>
    </div>
  );
}

export default Header;
