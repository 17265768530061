import { Apis } from "../constants/Apis";
import { fetchService } from "./service";

const SigninService: (body: {
  email: string;
  password: string;
}) => Promise<{ data: any; status: number }> = async (body) => {
  return fetchService({
    url: Apis.SIGNIN,
    method: "POST",
    addContentTypeApplicationJson: true,
    body: body,
    showSuccessMessage: false,
  });
};

const LogoutService: (
  token: string
) => Promise<{ data: any; status: number }> = async (token) => {
  return fetchService({
    url: Apis.LOGOUT,
    method: "POST",
    addContentTypeApplicationJson: true,
    token: token,
    showSuccessMessage: false,
    showErrorMessage: false,
  });
};

const VerifyCodeService: (body: {
  code: string;
  operationId: string;
}) => Promise<{ data: any; status: number }> = async (body) => {
  return fetchService({
    url: Apis.VERIFY_CODE,
    method: "POST",
    addContentTypeApplicationJson: true,
    body: body,
  });
};

export { SigninService, VerifyCodeService, LogoutService };
