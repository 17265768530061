import { Apis } from "../constants/Apis";
import { fetchService } from "./service";

const GetAllQuestions: (
  token: string,
  filterType: string,
  page: number,
  postId: string,
  flagType: string,
  sortType: string,
  searchText: string,
  showMessage?: boolean
) => Promise<{ data: any; status: number }> = async (
  token,
  filterType,
  page,
  postId,
  flagType,
  sortType,
  searchText,
  showMessage = true
) => {
  return fetchService({
    url: Apis.GET_QUESTIONS,
    method: "POST",
    addContentTypeApplicationJson: true,
    token: token,
    showSuccessMessage: false,
    body: {
      filterType,
      page,
      postId,
      flagType,
      sortType,
      searchText,
    },
  });
};
const BlockUserService: (
  token: string,
  userId: string,
  showMessage?: boolean
) => Promise<{ data: any; status: number }> = async (
  token,
  userId,
  showMessage = true
) => {
  return fetchService({
    url: Apis.BLOCK_USER,
    method: "POST",
    addContentTypeApplicationJson: true,
    token: token,
    showSuccessMessage: true,
    body: {
      userId,
    },
  });
};
const RejectApprovePostService: (
  token: string,
  questionId: string,
  action: "approve" | "reject",
  showMessage?: boolean
) => Promise<{ data: any; status: number }> = async (
  token,
  questionId,
  action,
  showMessage = true
) => {
  return fetchService({
    url: Apis.REJECT_QUESTION,
    method: "POST",
    addContentTypeApplicationJson: true,
    token: token,
    showSuccessMessage: true,
    body: {
      questionId,
      action,
    },
  });
};

export { GetAllQuestions, BlockUserService, RejectApprovePostService };
