import React, { useEffect, useState } from "react";
import styles from "./Filter.module.scss";
import { Dayjs } from "dayjs";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import SelectDate from "../../../Admins/SelectDate";

interface FilterProps {
  filterObj: {
    fromDate: Dayjs;
    toDate: Dayjs;
  };
  setFilterObj: (value: FilterProps["filterObj"]) => void;
  setRefresh: (val: number) => void;
  refresh: number;
  setIsAllTime: (val: boolean) => void;
  isAllTime: boolean;
  users: { _id: string; email: string }[];
  userid: string;
  setUserid: (val: string) => void;
}

function AdminsFilter({
  filterObj,
  setFilterObj,
  setRefresh,
  refresh,
  isAllTime,
  setIsAllTime,
  userid,
  users,
  setUserid,
}: FilterProps) {
  const [fromDate, setFromDate] = useState<Dayjs>(filterObj.fromDate);
  const [toDate, setToDate] = useState<Dayjs>(filterObj.toDate);

  useEffect(() => {
    setFilterObj({ ...filterObj, fromDate: fromDate });
  }, [fromDate]);

  useEffect(() => {
    setFilterObj({ ...filterObj, toDate: toDate });
  }, [toDate]);

  return (
    <div className={styles.main}>
      <FormControl style={{ marginLeft: 20, width: 250 }}>
        <InputLabel id="users-label">User</InputLabel>
        <Select
          labelId="users-label"
          id="users"
          value={userid}
          defaultValue={userid}
          label="User"
          onChange={(e) => {
            setUserid(e.target.value);
          }}
        >
          {users.map((item) => (
            <MenuItem
              selected={item._id === userid}
              key={item._id}
              value={item._id}
            >
              {item.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ marginLeft: 20 }}>
        <RadioGroup
          row
          value={isAllTime ? "All Time" : "Date Filter"}
          onChange={(e) => {
            setIsAllTime(e.target.value === "All Time");
          }}
        >
          <FormControlLabel
            value="All Time"
            control={<Radio />}
            label="All Time"
          />
          <FormControlLabel
            value="Date Filter"
            control={<Radio />}
            label="Date Filter"
          />
        </RadioGroup>
      </FormControl>
      <div className={styles.datepickerContainer}>
        {!isAllTime && (
          <>
            <SelectDate
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
            />
            <Button
              variant="contained"
              onClick={() => {
                setRefresh(refresh + 1);
              }}
            >
              Apply
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default AdminsFilter;
