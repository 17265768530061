import React from "react";
import styles from "./Content.module.scss";
import UserTable from "../UserTable/UserTable";
import PostTable from "../PostTable/PostTable";

function Content({
  data,
}: {
  data: {
    postTable: any;
    userTable: any;
  };
}) {
  return (
    <div className={styles.main}>
      <UserTable userTable={data.userTable} />
      <PostTable postTable={data.postTable} />
    </div>
  );
}

export default Content;
