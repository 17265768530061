import React from "react";
import { S3link } from "../../../../../constants/Apis";
import { PostProps } from "../../post/post";
import classes from "./imagePost.module.scss";

const ImagePost: React.FC<PostProps> = ({ ...post }) => {
  return (
    <div className={classes.main}>
      {post.image && (
        <div className={classes.image}>
          <img src={S3link + post.image} alt="post imsge" />
        </div>
      )}

      <div className={classes.options}>
        {post.options.map((option, index) => (
          <div key={index} className={classes.option}>
            <span className={classes.label}>{option.label} </span>
            <span className={classes.percent}>
              {`${
                post.votesCount === 0
                  ? 0
                  : Math.round((100 * option.votesCount) / post.votesCount)
              }
            %`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImagePost;
