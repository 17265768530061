import React, { InputHTMLAttributes } from 'react'
import classes from "./Input.module.scss"



interface  Inputprops extends InputHTMLAttributes<HTMLInputElement>{
    
    type:"text"|"password"

}
const Input:React.FC<Inputprops>=({type,...props}) =>{
  return (
    <input className={classes.main} {...props} type={type} />
  )
}

export default Input