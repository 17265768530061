import styles from "./Tables.module.scss";
import GroupsDetails from "./GroupsDetails";
import UsersDetails from "./UsersDetails";

function Tables({
  groupsDetails,
  usersDetails,
}: {
  groupsDetails: any;
  usersDetails: any;
}) {
  return (
    <div className={styles.main}>
      <div style={{ width: "100%" }}>
        <GroupsDetails groupsDetails={groupsDetails} />
      </div>
      <div style={{ width: "100%" }}>
        <UsersDetails usersDetails={usersDetails} />
      </div>
    </div>
  );
}

export default Tables;
