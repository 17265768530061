import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import React from "react";
import { OpingoTableProps } from "../../OpingoTable";

interface OpingoTableHeadProps {
  cols: OpingoTableProps["cols"];
  sortObj: OpingoTableProps["sortObj"];
  setSortObj: OpingoTableProps["setSortObj"];
}

function OpingoTableHead({ cols, sortObj, setSortObj }: OpingoTableHeadProps) {
  const createSortHandler = (field: string) => {
    if (sortObj.title === field) {
      setSortObj({
        title: sortObj.title,
        type: sortObj.type === "desc" ? "asc" : "desc",
      });
    } else {
      setSortObj({
        title: field,
        type: sortObj.type,
      });
    }
  };

  return (
    <TableHead>
      <TableRow>
        {cols.map((col, index) => (
          <TableCell
            style={{ width: "auto" }}
            key={index}
            align={col.align || "left"}
            padding={"none"}
            sortDirection={sortObj.title === col.title ? sortObj.type : false}
          >
            <TableSortLabel
              active={sortObj.title === col.field}
              direction={sortObj.title === col.field ? sortObj.type : "asc"}
              onClick={() => createSortHandler(col.field)}
              style={{ fontWeight: "600" }}
            >
              {col.title}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default OpingoTableHead;
