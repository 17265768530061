import React, { useState } from "react";
import styles from "./PostTable.module.scss";
import OpingoTable from "../../../../../components/Table/OpingoTable";

const cols = [
  {
    title: "Rank",
    field: "rank",
  },
  {
    title: "Post",
    field: "post",
  },
  {
    title: "UserName",
    field: "username",
  },
  {
    title: "Category",
    field: "category",
  },
  {
    title: "Duration",
    field: "duration",
  },
  {
    title: "Single/Multiple",
    field: "single_multiple",
  },
  {
    title: "Views",
    field: "views",
  },
  {
    title: "Votes",
    field: "votes",
  },
  {
    title: "Likes",
    field: "likes",
  },
  {
    title: "Comments",
    field: "comments",
  },
  {
    title: "Shares",
    field: "shares",
  },
  {
    title: "Votes Rate",
    field: "votes_rate",
  },
  {
    title: "Engagement Rate",
    field: "engagement_rate",
  },
  {
    title: "Open Date",
    field: "open_date",
  },
  {
    title: "Close Date",
    field: "close_date",
  },
];

function PostTable({ postTable }: { postTable: any }) {
  const [sortObj, setSortObj] = useState<{
    title: string;
    type: "asc" | "desc";
  }>({ title: "", type: "asc" });
  const [data, setData] = useState<any>(postTable);
  const [pageSize, setPageSize] = React.useState(5);
  const [pageNumber, setPageNumber] = React.useState(1);

  return (
    <div className={styles.main}>
      <OpingoTable
        title="Post View"
        data={data}
        cols={cols}
        sortObj={sortObj}
        setSortObj={setSortObj}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
        showTablePagination={false}
      />
    </div>
  );
}

export default PostTable;
