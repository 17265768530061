import React from "react";
import { RejectApprovePostService } from "../../../../services/Question";
import GalleryPost from "../postTypes/galleryPost/galleryPst";
import ImagePost from "../postTypes/imagePost/imagePost";
import Pairs from "../postTypes/pairs/pairs";
import SplitPost from "../postTypes/splitPost/splitPost";
import TextPost from "../postTypes/textPost/textPost";
import classes from "./post.module.scss";

export interface PostType {
  title: string;
  category: {
    _id: string;
    title: string;
  };
  expired: string;
  opened: string;
  options: { label: string; image?: string; votesCount: number }[];
  image: string | null;
  type: "Image" | "Pairs" | "Text" | "Gallery" | "Split";
  selectionType: "multiple" | "single";
  user: {
    _id: string;
    blockedByAdmin: string | null;
    username: string;
    name: string;
    avatar: string;
    status: string;
    followers: [];
    friends: [];
    comments: [];
    age: number;
    gender: number;
    genderText: string;
    email: string;
    createdAt: string;
    questions: string[];
    rejectedPostsCount: number;
  };
  votes: any[];
  votesCount: number;
  comments: any[];
  likes: any[];
  status: string;
  _id: string;
  shares: number;
  flags: number;
  rejecteddAt: string | null;
  deletedAt: string;
  reports: { user: { username: string }; key: string }[];
  reportsCount: number;
  moderationBlocked: boolean;
  textModerationBlocked: boolean;
  awsModerationBlocked: boolean;
  moderationLabels: string[];
  approvedByAdmin: {
    email: string;
  } | null;
  rejectedByAdmin: {
    email: string;
  } | null;
}
export interface PostProps extends PostType {
  changePostBlock?: (questionId: string, action: "approve" | "reject") => void;
}
const Post: React.FC<PostProps> = ({ ...post }) => {
  const ToggleApproveRejectPost = (action: "approve" | "reject") => {
    RejectApprovePostService(
      localStorage.getItem("token") || "",
      post._id,
      action
    ).then(({ data, status }) => {
      if (status === 200) {
        if (post.changePostBlock) {
          post.changePostBlock(post._id, action);
        }
      }
    });
  };
  return (
    <div className={classes.main}>
      <div className={classes.postImage}>
        {post.type === "Image" ? (
          <ImagePost {...post} />
        ) : post.type === "Pairs" ? (
          <Pairs {...post} />
        ) : post.type === "Text" ? (
          <TextPost {...post} />
        ) : post.type === "Gallery" ? (
          <GalleryPost {...post} />
        ) : post.type === "Split" ? (
          <SplitPost {...post} />
        ) : (
          ""
        )}
      </div>

      <div className={classes.buttonContainer}>
        {post.rejecteddAt === null && (
          <button
            onClick={() => ToggleApproveRejectPost("reject")}
            className={classes.reject}
          >
            Reject
          </button>
        )}
        <button
          onClick={() => ToggleApproveRejectPost("approve")}
          className={classes.approve}
        >
          Approve
        </button>
      </div>
    </div>
  );
};

export default Post;
