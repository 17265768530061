import React, { useState } from "react";
import { S3link } from "../../../../constants/Apis";
import classes from "./categories.module.scss";

interface CategoryProps {
  catId: string;
  categories: { _id: string; title: string; image: string }[];
}

const Categories: React.FC<CategoryProps> = ({ catId, categories }) => {
  return (
    <div className={classes.main}>
      <span className={classes.title}>Category</span>
      <div className={classes.content}>
        {categories.map((category) => (
          <div
            key={category._id}
            className={`${classes.category} ${
              category._id === catId ? classes.activeCategory : ""
            }`}
          >
            <img src={`${S3link}${category.image}`} alt={category.title} />
            <span>{category.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
