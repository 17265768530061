import React from "react";
import classes from "./sideMenu.module.scss";
import ReportOffImg from "../../../../assets/images/Report.png";
import ReportOnImg from "../../../../assets/images/danger-on.png";
import topArrowImg from "../../../../assets/images/topArrow.png";
import bottomArrowImg from "../../../../assets/images/bottomArrow.png";
import allOnImg from "../../../../assets/images/all-on.png";
import allOffImg from "../../../../assets/images/all-off.png";
import crossOffImg from "../../../../assets/images/close-off.png";
import crossOnImg from "../../../../assets/images/close-on.png";
import personOffImg from "../../../../assets/images/user-off.png";
import personOnImg from "../../../../assets/images/user-on.png";

interface SideMenuProps {
  goToNext: () => void;
  goToPrivious: () => void;
  activeIndex: number;
  setFilterType: (val: string) => void;
  filterType: string;
}

const SideMenu: React.FC<SideMenuProps> = ({
  goToNext,
  goToPrivious,
  activeIndex,
  setFilterType,
  filterType,
}) => {
  return (
    <div className={classes.main}>
      <div>
        <img
          onClick={() => {
            setFilterType("");
          }}
          src={filterType === "" ? allOnImg : allOffImg}
          alt="all"
        />
        <img
          onClick={() => {
            setFilterType("report");
          }}
          src={filterType === "report" ? ReportOnImg : ReportOffImg}
          alt="report"
        />
        <img
          onClick={() => {
            setFilterType("rejected");
          }}
          src={filterType === "rejected" ? crossOnImg : crossOffImg}
          alt="rejected"
        />
        <img
          onClick={() => {
            setFilterType("user");
          }}
          src={filterType === "user" ? personOnImg : personOffImg}
          alt="user"
        />
      </div>
      <div>
        <img
          style={{
            opacity: activeIndex === 0 ? 0.4 : 1,
          }}
          src={topArrowImg}
          alt="report"
          onClick={() => {
            if (activeIndex !== 0) {
              goToPrivious();
            }
          }}
        />
        <img src={bottomArrowImg} alt="report" onClick={goToNext} />
      </div>
    </div>
  );
};

export default SideMenu;
