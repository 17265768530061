import "./App.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./routes/login/login";
import Home from "./routes/home/home";
import VerifyCode from "./routes/login/verifyCode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Console from "./routes/AdminPortal/Console/Console";
import Reports from "./routes/AdminPortal/Reports/Reports";
import { LogoutService } from "./services/Signin";
import Header from "./routes/home/components/header/header";
import Admins from "./routes/AdminPortal/Admins/Admins";

function App() {
  const routes = [
    {
      component: <Login />,
      path: "/",
    },
    {
      component: <Home />,
      path: "/home",
    },
    {
      component: <Home />,
      path: "/home/:postId",
    },
    {
      component: <VerifyCode />,
      path: "/verify-code",
    },
    {
      component: <Console />,
      path: "/console",
    },
    {
      component: <Reports />,
      path: "/reports",
    },
    {
      component: <Reports />,
      path: "/reports",
    },
    {
      component: <Admins />,
      path: "/admins",
    },
  ];
  const logoutHandler = () => {
    const token = localStorage.getItem("token");
    LogoutService(token || "").then(({ data, status }) => {
      if (status === 200) {
        localStorage.clear();
        window.location.href = "/";
        window.location.reload();
      }
    });
  };
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <div className="App">
      {pathname === "/" ||
      pathname === "" ||
      pathname === "/verify-code" ||
      pathname === "/verify-code/" ? null : (
        <Header logoutHandler={logoutHandler} />
      )}
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} element={route.component} path={route.path} />
        ))}
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
