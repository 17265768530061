import React, { useState } from "react";
import styles from "./Tables.module.scss";
import CategoryTable from "./CategoryTable";
import InterestTable from "./InterestTable";
import PostTypeTable from "./PostTypeTable";
import DurationTable from "./DurationTable";
import SingleMultipleTable from "./SingleMultipleTable";

function Tables({
  postByCategories,
  usersByCategories,
  postByTypes,
  postByDuration,
  postBySelectionType,
}: {
  postByCategories: any;
  usersByCategories: any;
  postByTypes: any;
  postByDuration: any;
  postBySelectionType: any;
}) {
  return (
    <div className={styles.main}>
      <div style={{ width: "69%" }}>
        <CategoryTable postByCategories={postByCategories} />
      </div>
      <div style={{ width: "29%" }} className={styles.secondCol}>
        <InterestTable usersByCategories={usersByCategories} />
      </div>
      <div style={{ width: "32%" }} className={styles.secondCol}>
        <PostTypeTable postByTypes={postByTypes} />
      </div>
      <div style={{ width: "32%" }} className={styles.secondCol}>
        <DurationTable postByDuration={postByDuration} />
      </div>
      <div style={{ width: "32%" }} className={styles.secondCol}>
        <SingleMultipleTable postBySelectionType={postBySelectionType} />
      </div>
    </div>
  );
}

export default Tables;
