import React from "react";
import styles from "./Statistics.module.scss";

function Statistics({
  parameters,
  secondParameters,
}: {
  parameters: any;
  secondParameters: any;
}) {
  return (
    <div className={styles.main}>
      <div className={styles.parameters}>
        {parameters?.map((parameter: any, index: number) => {
          return (
            <div key={index} className={styles.parameterItem}>
              <span className={styles.parameterItem__value}>
                {parameter.value}
              </span>
              <span className={styles.parameterItem__label}>
                {parameter.label}
              </span>
              {parameter.subLabel && (
                <span className={styles.parameterItem__subLabel}>
                  {parameter.subLabel}
                </span>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.parameters}>
        {secondParameters?.map((parameter: any, index: number) => {
          return (
            <div key={index} className={styles.parameterItem}>
              <span className={styles.parameterItem__value}>
                {parameter.value}
              </span>
              <span className={styles.parameterItem__label}>
                {parameter.label}
              </span>
              {parameter.subLabel && (
                <span className={styles.parameterItem__subLabel}>
                  {parameter.subLabel}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Statistics;
