import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import styles from "./SelectDate.module.scss";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

export interface SelectDateProps {
  fromDate: Dayjs;
  setFromDate: (value: SelectDateProps["fromDate"]) => void;
  toDate: Dayjs;
  setToDate: (value: SelectDateProps["toDate"]) => void;
}

function SelectDate({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}: SelectDateProps) {
  return (
    <div className={styles.main}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={fromDate}
          onChange={(newValue) =>
            setFromDate(
              newValue ||
                dayjs(
                  moment().endOf("day").subtract(6, "days").format("YYYY-MM-DD")
                )
            )
          }
          disableFuture
        />
        <DatePicker
          minDate={fromDate}
          label="End Date"
          value={toDate}
          onChange={(newValue) =>
            setToDate(newValue || dayjs(moment().format("YYYY-MM-DD")))
          }
          disableFuture
        />
      </LocalizationProvider>
    </div>
  );
}

export default SelectDate;
