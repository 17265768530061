import classes from "./home.module.scss";
// import { LogoutService } from "../../services/Signin";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Header from "./components/header/header";
import Content from "./components/content/content";
import { LogoutService } from "../../services/Signin";

function Home() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  if (!token || token.length === 0) {
    navigate("/");
  }
  const [activeMenu, setActiveMenu] = useState(2);

  return (
    <div className={classes.main}>
      <Content />
    </div>
  );
}

export default Home;
