import React, { useEffect, useState } from "react";
import OpingoTable from "../../../../../../../../components/Table/OpingoTable";

function DurationTable({ postByDuration }: { postByDuration: any }) {
  const [pageSize, setPageSize] = React.useState(5);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [sortObj, setSortObj] = useState<{
    title: string;
    type: "asc" | "desc";
  }>({ title: "", type: "asc" });
  const [data, setData] = useState<any>(postByDuration);

  const cols = [
    {
      title: "Duration",
      field: "duration",
      type: "number",
    },
    {
      title: "Posts",
      field: "posts",
      type: "number",
    },
  ];
  useEffect(() => {
    let type = "number";
    for (let i = 0; i < cols.length; i++) {
      const col = cols[i];
      if (col.field === sortObj.title) {
        type = col.type;
      }
    }
    setData(
      data.sort((item1: any, item2: any) => {
        if (type === "number") {
          if (Number(item1[sortObj.title]) > Number(item2[sortObj.title])) {
            if (sortObj.type === "desc") {
              return 1;
            }
            return -1;
          }
          if (sortObj.type === "desc") {
            return -1;
          }
          return 1;
        } else {
          if (item1[sortObj.title] > item2[sortObj.title]) {
            if (sortObj.type === "desc") {
              return 1;
            }
            return -1;
          }
          if (sortObj.type === "desc") {
            return -1;
          }
          return 1;
        }
      })
    );
  }, [sortObj]);
  return (
    <OpingoTable
      data={data}
      cols={cols}
      sortObj={sortObj}
      setSortObj={setSortObj}
      pageSize={pageSize}
      pageNumber={pageNumber}
      setPageSize={setPageSize}
      setPageNumber={setPageNumber}
      showTablePagination={false}
    />
  );
}

export default DurationTable;
