import React from "react";
import { S3link } from "../../../../../constants/Apis";
import { PostProps } from "../../post/post";
import classes from "./galleryPost.module.scss";

const GalleryPost: React.FC<PostProps> = ({ ...post }) => {
  return (
    <div className={classes.main}>
      <div className={classes.options}>
        {post.options.map((option, index) => (
          <div key={index} className={classes.option}>
            <img src={S3link + option.image} alt="" />
            {option.label && (
              <span className={classes.label}>{option.label} </span>
            )}
            <span className={classes.percent}>
              {`${
                post.votesCount === 0
                  ? 0
                  : Math.round((100 * option.votesCount) / post.votesCount)
              }
            %`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GalleryPost;
