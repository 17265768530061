import moment from "moment";
import React from "react";
import { PostProps } from "../post/post";
import classes from "./postInfo.module.scss";

const reportsText = [
  { key: "NOT-A_QUESTION", label: "Not a Question" },
  { key: "NUDITY", label: "Nudity or Sexual" },
  { key: "HATE_SPEECH", label: "Hate Speech" },
  { key: "DONT_LIKE_IT", label: "Don’t Like it" },
  { key: "GRAMMER_ISSUE", label: "Grammar Issue or Typo" },
  { key: "SPAM", label: "Spam or Scam" },
];
const findReportLabel = (key: string) => {
  let label = "";
  reportsText.forEach((rt) => {
    if (rt.key === key) {
      label = rt.label;
    }
  });
  return label;
};
const PostInfo: React.FC<PostProps> = ({ ...post }) => {
  const infoList = [
    {
      title: "Post",
      value: post.deletedAt
        ? "Deleted"
        : post.rejecteddAt
        ? post.reportsCount > 0
          ? "Rejected"
          : "Rejected (Admin)"
        : post.reportsCount > 0
        ? post.moderationBlocked
          ? "Flagged"
          : "Approved (Flagged)"
        : "Approved",
      greenText: true,
      redText: post.deletedAt || post.rejecteddAt ? true : false,
      orangeText: post.reportsCount > 0 ? true : false,
    },
    {
      title: "Flagged By",
      value:
        post.reportsCount > 0
          ? post.awsModerationBlocked
            ? "AWS"
            : post.textModerationBlocked
            ? "Keywords"
            : post.reports[0].user.username
          : "Not Flagged",
      greenText: true,
      redText: post.deletedAt || post.rejecteddAt ? true : false,
      orangeText: post.reportsCount > 0 ? true : false,
    },
    {
      title: "Reason",
      value: post.rejecteddAt
        ? `Admin ${post.rejectedByAdmin?.email}`
        : post.moderationBlocked
        ? post.textModerationBlocked
          ? `Keyword ${post.moderationLabels[0]}`
          : `Keyword ${post.moderationLabels[0]}`
        : post.textModerationBlocked || post.awsModerationBlocked
        ? `Admin ${post.approvedByAdmin?.email}`
        : "OK",
      greenText: true,
      redText: post.deletedAt || post.rejecteddAt ? true : false,
      orangeText: post.reportsCount > 0 ? true : false,
    },
    {
      title: "Post ID",
      value: post._id,
    },
    {
      title: "Number of Votes",
      value: post.votesCount,
    },
    {
      title: "Number of Likes",
      value: post.likes.length,
    },
    {
      title: "Number of Shares",
      value: 0,
    },
    {
      title: "Number of Comments",
      value: post.comments.length,
    },
    {
      title: "Number of Flags",
      value: post.reports.length,
    },
    {
      title: "Post Circle",
      value: 1,
    },
    {
      title: "Start Date",
      value: moment(post.opened, "YYYY-MM-DDTHH:mm:ss").format(
        "MM/DD/YYYY HH:mm"
      ),
    },
    {
      title: "End Date",
      value: moment(post.expired, "YYYY-MM-DDTHH:mm:ss").format(
        "MM/DD/YYYY HH:mm"
      ),
    },
  ];

  return (
    <div className={classes.main}>
      <span className={classes.title}>Post </span>
      <div className={classes.content}>
        {infoList.map((item, index) => (
          <div key={index} className={classes.item}>
            <span>{item.title} :</span>
            <span
              className={`${
                item.redText
                  ? classes.redText
                  : item.orangeText
                  ? classes.orangeText
                  : item.greenText
                  ? classes.greenText
                  : ""
              }`}
            >
              {item.value}{" "}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PostInfo;
