import React from "react";
import classes from "./OpingoTable.module.scss";
import OpingoTableHead from "./components/OpingoTableHead/OpingoTableHead";
import OpingoTableRow from "./components/OpingoTableRow/OpingoTableRow";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";

export interface OpingoTableProps {
  cols: {
    title: string;
    field: string;
    numeric?: boolean;
    align?: "left" | "center" | "right";
  }[];
  data: any[];
  sortObj: { title: string; type: "asc" | "desc" };
  setSortObj: (value: OpingoTableProps["sortObj"]) => void;
  title?: string;
  pageSize: number;
  pageNumber: number;
  setPageSize: (value: number) => void;
  setPageNumber: (value: number) => void;
  showTablePagination?: boolean;
}

export default function OpingoTable({
  cols,
  data,
  sortObj,
  setSortObj,
  title,
  pageSize,
  pageNumber,
  setPageSize,
  setPageNumber,
  showTablePagination = true,
}: OpingoTableProps) {
  const handleChangePage = (event: any, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  return (
    <div className={classes.main}>
      {title && (
        <div className={classes.title}>
          <span>{title}</span>
        </div>
      )}
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <OpingoTableHead
              cols={cols}
              sortObj={sortObj}
              setSortObj={setSortObj}
            />
            <TableBody>
              {data?.map((row: any, index: number) => {
                return (
                  <OpingoTableRow
                    cols={cols}
                    key={row._id || index}
                    row={row}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {showTablePagination && (
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={data ? data.length : 0}
            rowsPerPage={pageNumber}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}
