import { toast } from "react-toastify";

const fetchService: ({
  token,
  url,
  method,
  addContentTypeApplicationJson,
  body,
  formData,
  showSuccessMessage,
}: {
  token?: string;
  url: string;
  method?: string;
  addContentTypeApplicationJson?: boolean;
  body?: any;
  formData?: any;
  showSuccessMessage?: boolean;
  showErrorMessage?: boolean;
}) => Promise<{ data: any; status: number }> = async ({
  token = "",
  url,
  method = "GET",
  addContentTypeApplicationJson = false,
  body,
  formData,
  showSuccessMessage = true,
  showErrorMessage = true,
}) => {
  let status: number;
  const headers: any = {};
  if (token) {
    headers["Authorization"] = token;
  }
  if (addContentTypeApplicationJson) {
    headers["Content-Type"] = "application/json";
  }
  let fetchBody: any;

  if (body) {
    fetchBody = JSON.stringify(body);
  } else if (formData) {
    fetchBody = formData;
  }
  try {
    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: fetchBody,
    });
    status = response.status;
    const responseJson = await response.json();
    if (status === 401) {
      localStorage.removeItem("token");
      showErrorMessage && toast.error(responseJson.message);
      // window.location.reload()
      // window.location.href="/"
      return { status: 401, data: {} };
    } else if (status === 400) {
      showErrorMessage && toast.error(responseJson.message);
      return { status: status, data: responseJson };
    } else {
      showSuccessMessage && toast.success(responseJson.message);
      return { status: status, data: responseJson };
    }
  } catch (e) {
    showErrorMessage && toast.error("Network Error!");
    return { status: 500, data: {} };
  }
};
export { fetchService };
