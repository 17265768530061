import moment from "moment";
import React from "react";
import { PostProps } from "../post/post";
import classes from "./userInfo.module.scss";

const UserInfo: React.FC<PostProps["user"]> = ({ ...user }) => {
  const infoList = [
    {
      title: "User Status ",
      value: user.blockedByAdmin ? "Blocked" : "Active",
      greenText: true,
      redText: user.blockedByAdmin ? true : false,
    },
    {
      title: "Number of followers",
      value: user.followers.length,
    },
    {
      title: "Number of Followings",
      value: user.followers.length,
    },
    {
      title: "Number of Friends",
      value: 0,
    },
    {
      title: "Number of Groups",
      value: 0,
    },
    {
      title: "Number of Posts",
      value: user.questions.length,
    },
    {
      title: "Number of Comments",
      value: user.comments.length,
    },
    {
      title: "Number of Rejected Post",
      value: user.rejectedPostsCount,
    },
    {
      title: "Gender, Age",
      value: `${
        user.gender === 0
          ? "Male"
          : user.gender === 1
          ? "Famale"
          : user.genderText
      }, ${user.age}`,
    },
    {
      title: "Location",
      value: "",
    },
    {
      title: "Email",
      value: user.email,
    },
    {
      title: "Date Joined",
      value: moment(user.createdAt, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY"),
    },
  ];

  return (
    <div className={classes.main}>
      <span className={classes.title}>
        {`${user.username} as ${user.name}`}{" "}
      </span>
      <div className={classes.content}>
        {infoList.map((item, index) => (
          <div key={index} className={classes.item}>
            <span>{item.title} : </span>
            <span
              className={`${
                item.redText
                  ? classes.redText
                  : item.greenText
                  ? classes.greenText
                  : ""
              }`}
            >
              {item.value}{" "}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserInfo;
