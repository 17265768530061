import React, { useState } from "react";
import styles from "./UserTable.module.scss";
import OpingoTable from "../../../../../components/Table/OpingoTable";

const cols = [
  {
    title: "Rank",
    field: "rank",
  },
  {
    title: "Name",
    field: "name",
  },
  {
    title: "UserName",
    field: "username",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Gender",
    field: "gender",
  },
  {
    title: "Age",
    field: "age",
  },
  {
    title: "Posts",
    field: "posts",
  },
  {
    title: "Followers",
    field: "followers",
  },
  {
    title: "Views",
    field: "views",
  },
  {
    title: "Viewed",
    field: "viewed",
  },
  {
    title: "Votes",
    field: "votes",
  },
  {
    title: "Voted",
    field: "voted",
  },
  {
    title: "Likes",
    field: "likes",
  },
  {
    title: "Liked",
    field: "liked",
  },
  {
    title: "Comments",
    field: "comments",
  },
  {
    title: "Commented",
    field: "commented",
  },
  {
    title: "Shares",
    field: "shares",
  },
  {
    title: "Shared",
    field: "shared",
  },
  {
    title: "Category",
    field: "category",
  },
  {
    title: "Votes Rate",
    field: "votes_rate",
  },
  {
    title: "Voted Rate",
    field: "voted_rate",
  },
  {
    title: "Engagement Rate %",
    field: "engagement_rate",
  },
  {
    title: "Last Post",
    field: "last_post",
  },
  {
    title: "Date Joined",
    field: "date_joined",
  },
];
function UserTable({ userTable }: { userTable: any }) {
  const [sortObj, setSortObj] = useState<{
    title: string;
    type: "asc" | "desc";
  }>({ title: "", type: "asc" });

  const [data, setData] = useState<any>(userTable);

  const [pageSize, setPageSize] = React.useState(5);
  const [pageNumber, setPageNumber] = React.useState(1);

  return (
    <div className={styles.main}>
      <OpingoTable
        title="User View"
        data={data}
        cols={cols}
        sortObj={sortObj}
        setSortObj={setSortObj}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setPageSize={setPageSize}
        setPageNumber={setPageNumber}
        showTablePagination={false}
      />
    </div>
  );
}

export default UserTable;
