// const BaseApi = "http://localhost:8000";
const BaseApi = "https://backend.opingoapp.com";
const S3link = "https://opingo.s3.us-east-1.amazonaws.com/";

const Apis = {
  SIGNIN: `${BaseApi}/signin-admin`,
  LOGOUT: `${BaseApi}/logout-admin`,
  VERIFY_CODE: `${BaseApi}/verify-code-admin`,
  GET_CATEGORIES: `${BaseApi}/categories`,
  GET_QUESTIONS: `${BaseApi}/admin/questions`,
  BLOCK_USER: `${BaseApi}/admin/block-user`,
  REJECT_QUESTION: `${BaseApi}/admin/reject-question`,
  GET_CONSOLE_DATA: `${BaseApi}/admin/console`,
  GET_Report_DATA: `${BaseApi}/admin/report`,
  GET_ADMINS_DATA: `${BaseApi}/admin/admins`,
  GET_USERS_DATA: `${BaseApi}/admin/users`,
};
export { Apis, BaseApi, S3link };
