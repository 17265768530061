import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { OpingoTableProps } from "../../OpingoTable";

interface OpingoTableRowProps {
  row: any;
  cols: OpingoTableProps["cols"];
}

function OpingoTableRow({ row, cols }: OpingoTableRowProps) {
  return (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row.name)}
      //   role="checkbox"
      // aria-checked={isItemSelected}
      //   tabIndex={-1}

      // selected={isItemSelected}
    >
      {cols.map((col, index) => {
        return (
          <TableCell
            component="th"
            align={col.numeric ? "right" : "left"}
            key={index}
            scope="row"
            padding="none"
          >
            {row[col.field]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default OpingoTableRow;
