import React, { useEffect, useState } from "react";
import styles from "./Reports.module.scss";
import Content from "./components/content/Content";
import Filter from "../Console/components/Filter/Filter";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getReportDataService } from "../../../services/Report";
import { Alert, CircularProgress } from "@mui/material";

function Reports() {
  const [filterObj, setFilterObj] = useState<{
    users: "ALL Users" | "App Users";
    fromDate: Dayjs;
    toDate: Dayjs;
  }>({
    users: "ALL Users",
    fromDate: dayjs(
      moment().endOf("day").subtract(6, "days").format("YYYY-MM-DD")
    ),
    toDate: dayjs(moment().format("YYYY-MM-DD")),
  });
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  if (!token || token.length === 0) {
    navigate("/");
  }
  const [isAllTime, setIsAllTime] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<{
    postTable: any;
    userTable: any;
  } | null>(null);
  const getData = () => {
    if (!loading) {
      setLoading(true);
    }
    getReportDataService(
      token || "",
      filterObj.fromDate,
      filterObj.toDate,
      filterObj.users,
      isAllTime
    ).then(({ data, status }) => {
      if (status === 200) {
        setData(data.data);
      }
      setLoading(false);
    });
  };
  const [refresh, setRefresh] = useState(0);
  const [firstTime, setFirstTime] = useState(true);
  useEffect(() => {
    if (!firstTime) {
      getData();
    }
  }, [refresh, filterObj.users]);
  useEffect(() => {
    if (isAllTime && !firstTime) {
      getData();
    }
  }, [isAllTime]);
  useEffect(() => {
    getData();
    setFirstTime(false);
  }, []);
  return (
    <div className={styles.main}>
      {loading ? (
        <CircularProgress />
      ) : data === null ? (
        <Alert severity="error">Error</Alert>
      ) : (
        <>
          <Filter
            setRefresh={setRefresh}
            filterObj={filterObj}
            setFilterObj={setFilterObj}
            refresh={refresh}
            setIsAllTime={setIsAllTime}
            isAllTime={isAllTime}
          />
          <Content data={data} />
        </>
      )}
    </div>
  );
}

export default Reports;
